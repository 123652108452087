$_COOKIE = {};
eval((document.cookie + ';').replace(/([\w%]+)=?([^;]*);/g, "$$_COOKIE['$1']='$2';"));

function setCookieF(name, value, expires, path, domain, secure) {
   document.cookie = name + '=' + escape(value || '') + ( expires ? ';expires=' + new Date(+new Date() + expires * 864e5).toGMTString() : '') + "; path=/" + ( domain ? ';domain=' + domain : '') + ( secure ? ';secure' : '');
}

function setFontSizeCookie(size) {
   //alert(size);
   setCookieF('fontSize', size);
   $_COOKIE.fontSize = size;
   reSize(size);
   setFontSize();
   return false;
}

function setFontSize() {
   if ($_COOKIE.fontSize != "undefined") {
      document.documentElement.className = $_COOKIE.fontSize;
      var size = $_COOKIE.fontSize;
      reSize(size);

   }

}

function reSize(size) {
   if (size == 'big_f') {
      //font
      $('.ab_font').addClass('a_font').removeClass('ab_font');
      $('.aab_font').addClass('aa_font').removeClass('aab_font');
      $('.aaa_font').addClass('aaab_font');
      $('.sprite.bigf').addClass('bigf_active').removeClass('bigf');
      $('.sprite.mediumf_active').addClass('mediumf').removeClass('mediumf_active');
      $('.sprite.normalf_active').addClass('normalf').removeClass('normalf_active');

      if ($(window).width() < 910) {
         $('.wcag').addClass('wcag--top');
         $('.nav_btn').addClass('nav_btn--top');
         $('.sprite.contrast.active').css('top','-45px');
      }

   }
   if (size == 'medium_f') {
      //font
      $('.ab_font').addClass('a_font').removeClass('ab_font');
      $('.aaab_font').addClass('aaa_font').removeClass('aaab_font');
      $('.aa_font').addClass('aab_font');
      $('.sprite.mediumf').addClass('mediumf_active').removeClass('mediumf');
      $('.sprite.bigf_active').addClass('bigf').removeClass('bigf_active');
      $('.sprite.normalf_active').addClass('normalf').removeClass('normalf_active');
          if ($(window).width() < 820) {
         $('.wcag').addClass('wcag--top');
         $('.nav_btn').addClass('nav_btn--top');
         $('.sprite.contrast.active').css('top','-45px');
      }


   }
   if (size == 'normal_f') {
      //font
      $('.a_font').addClass('ab_font').removeClass('a_font');
      $('.aab_font').addClass('aa_font').removeClass('aab_font');
      $('.aaab_font').addClass('aaa_font').removeClass('aaab_font');
      $('.sprite.normalf').addClass('normalf_active').removeClass('normalf');
      $('.sprite.bigf_active').addClass('bigf').removeClass('bigf_active');
      $('.sprite.mediumf_active').addClass('mediumf').removeClass('mediumf_active');
      $('.wcag').removeClass('wcag--top');
      $('.nav_btn').removeClass('nav_btn--top');
       $('.sprite.contrast.active').css('top','-30px');
   }
}

//document.addEventListener("DOMContentLoaded", setFontSize, false);

if (!document.addEventListener) {
   document.attachEvent("DOMContentLoaded", setFontSize);
} else {
   document.addEventListener("DOMContentLoaded", setFontSize, false);
}

